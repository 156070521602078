import styled, { keyframes } from "styled-components";

const progress = keyframes`
  0% { 
    background-position: -100%; 
  }
  100% { 
    background-position: 200%; 
  }
`;

export const Container = styled.div`
    display: flex;
    gap: 20px;
    //background-color: #f2f2f2;
    height: calc(100vh - 140px);
    overflow: hidden;
    min-width: 100%;
    flex-direction: column;
`

export const Banner = styled.div`
    display: flex;
    min-height: 150px;
    background-color: #f2f2f2;
    min-width: 100%;
    border-radius: 8px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

export const BoxPlan = styled.div`
    display: flex;
    gap: 20px;
    overflow: hidden;
    min-height: 450px;
    width: 100%;
    max-width: 100%;
    //background-color: aliceblue;

    border-radius: 8px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

export const Plan = styled.div`
    display: flex;
    min-height: 450px;
    background-color: #f2f2f2;
    min-width: 300px;
    width: 300px;

    border-radius: 8px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

export const Details = styled.div`
    display: flex;
    min-height: 1050px;
    background-color: #f2f2f2;
    min-width: 100%;

    border-radius: 8px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
   
`
