import { EllipsisVerticalIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
//import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
//import ModalEditEmail from "../../modal-edit-email"
import { useDispatch } from "react-redux"
import { FaWhatsapp } from "react-icons/fa"
import ModalMenuItem from "../../modal-menu-item"
import ModalEditEmail from "../../modal-edit-email"
import { BoxItemImage, BoxItemNumber } from "./styled"
import { TextQtd } from "../../../../medias/components/tailwind/item-table/styled"
import { useHistory } from "react-router-dom"
//import { BoxImage } from "../../../../reviews/components/tailwind/item-table/styled"
//import { getTranslation } from "../../../../../translations"


const ItemTable = (props) => {

    // const [visibleMenu, setvisibleMenu] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const dispatch = useDispatch()
    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    const history = useHistory()

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    // const closeMenu = () => { setvisibleMenu(false) }
    // useEffect(() => {
    //     if (visibleMenu) {
    //         window.addEventListener('mouseup', closeMenu);
    //         return () => { window.removeEventListener('mouseup', closeMenu); };
    //     }


    // }, [visibleMenu])

    useEffect(() => {
        console.log("props item", props)
    }, [])




    const sendEmail = () => {
        dispatch({
            type: "SAGA_RESEND_EMAIL",
            payload: { order: props.item },
        });
    }

    // const sendWhats = () => {
    //     dispatch({
    //         type: "SAGA_RESEND_WHATS_ORDERS",
    //         payload: { order: [props.item] },
    //     });
    //     props.setSelectedOrders([])
    // }

    return (
        <>
            {/* <ModalEditEmail goSearch={props.goSearch} item={props.item} visible={visibleModal} setVisibleModal={setVisibleModal} /> */}

            <ModalMenuItem setVisibleModalEdit={setVisibleModal} item={props.item} visible={visibleModalMenu} setVisibleModal={setvisibleModalMenu} />
            <ModalEditEmail id={props.item.id} visible={visibleModal} setVisibleModal={setVisibleModal} />
            <tr key={"person.email"} className={props.selectedOrders.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedOrders.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.email}
                        checked={props.selectedOrders.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedOrders(
                                e.target.checked
                                    ? [...props.selectedOrders, props.item]
                                    : props.selectedOrders.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedOrders.includes(props.item) ? 'text-gray-900' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.created_at).toLocaleString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => { window.open(props.item.order_link, '_blank') }}>
                        #{props.item.order_number != null ? props.item.order_number : props.item.order_id}
                    </TextQtd>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {props.item.customer_name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {props.item.customer_email}
                </td>
                <td style={{ display: "flex" }} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {/* <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <label style={{ display: "flex", justifyContent: "center",  }}>
                            {JSON.parse(props.item.order_items).length}
                        </label>
                    </div> */}

                    <TextQtd onClick={() => { history.push(`/pedidos/${props.item.id}/produtos?pageOrder=${props.pageOrder}`) }} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {JSON.parse(props.item.order_items).length}
                    </TextQtd>

                    {/* <BoxItemImage >
                        <img
                            alt={"commenter.name"}
                            src={"https://down-br.img.susercontent.com/file/dbfdeb184946126655e1a9152fe40072"}

                        />
                    </BoxItemImage>
                    {JSON.parse(props.item.order_items).length > 1 &&
                        <BoxItemImage>
                            <img
                                style={{ marginLeft: "-10px" }}
                                alt={"commenter.name"}
                                src={"https://down-br.img.susercontent.com/file/dbfdeb184946126655e1a9152fe40072"}

                            />
                        </BoxItemImage>}

                    {JSON.parse(props.item.order_items).length > 2 &&
                        <BoxItemImage>
                            <img
                                alt={"commenter.name"}
                                src={"https://down-br.img.susercontent.com/file/dbfdeb184946126655e1a9152fe40072"}
                                style={{ marginLeft: "-10px" }}
                            />
                        </BoxItemImage>}

                    {JSON.parse(props.item.order_items).length > 4 &&
                        <BoxItemNumber>
                            + {parseInt(JSON.parse(props.item.order_items).length) - parseInt(3)}
                        </BoxItemNumber>} */}
                </td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <label style={{ width: "120px", }} className={props.item.is_canceled != 1 ? "bg-green-50 text-green-700 p-2 rounded-1 flex justify-center" : "bg-red-50 text-red-700 p-2 rounded-1 flex justify-center"} >
                            {props.item.is_canceled == 1 ? "Bloqueado" : "Liberado"}
                        </label>
                    </div>
                </td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <label style={{ width: "120px", }} className={props.item.is_email_send != 1 ? "bg-green-50 text-green-700 p-2 rounded-1 flex justify-center" : "bg-yellow-50 text-yellow-700 p-2 rounded-1 flex justify-center"} >
                            Enviado
                        </label>
                    </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => { setvisibleModalMenu(true) }} style={{ height: "32px", cursor: "pointer" }} />
                        {/* <Submenu isVisible={visibleMenu}>
                            <div onClick={() => { setVisibleModal(true) }}>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </BoxIcon>
                                <span>
                                    <p> {translation.orders.editarEmail}</p>
                                    <label> {translation.orders.atualizarEmail}</label>
                                </span>
                            </div>
                            <div>
                                <BoxIcon>
                                    <EnvelopeIcon style={{ width: "20px" }} />
                                </BoxIcon>
                                <span onClick={() => { sendEmail() }}>
                                    <p> {translation.orders.reenviarEmail}</p>
                                    <label> {translation.orders.envieReenvieEmail}</label>
                                </span>
                            </div>
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null &&
                                <div>
                                    <BoxIcon>
                                        <FaWhatsapp size={20} />
                                    </BoxIcon>
                                    <span onClick={() => { sendWhats() }}>
                                        <p> {translation.orders.reenviarWhatsapp}</p>
                                        <label>{translation.orders.envieReenvieWhatsapp}</label>
                                    </span>
                                </div>}
                        </Submenu> */}
                    </span>
                </td>
            </tr >
        </>

    )
}

export default ItemTable