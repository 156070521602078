import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
//import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
//import ButtonSinc from '../../button-sinc';
import { toast } from 'react-toastify';
import Navbar from '../navbar';
import { BarFilters, BarFiltersLeft, Search } from './styled';
import FiltersProducts from '../filters-medias';
import { ContainerLoading } from '../../../../../components/Loading-logo/styled';
import LoadingLogo from '../../../../../components/Loading-logo';
import FiltersMedias from '../filters-medias';
import ModalMenuMedia from '../../modal-menu-media';
import ModalUploadFiles from '../../modal-upload-files';
import ModalConectarAnexo from '../../../../products/components/modal-conectar-anexo';
import ModalUploadAnexoNew from '../../../../products/components/modal-upload-anexo-new';
import ModalMenuCad from '../../modal-menu-cad';
import ModalCadLinkNew from '../../../../products/components/modal-cad-link-new';
import ModalFeedDelete from '../modal-feed-delete';
import ModalFeedCompleted from '../../../../../components/tailwind/modal-feed-completed';
import LazyLoading from '../../../../../components/lazy-loading';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableMedias(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedMedias, setSelectedMedias] = useState([])
    const dispatch = useDispatch()

    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    const [visibleModalUploadFiles, setVisibleModalUploadFiles] = useState(false)
    const [visibleModalConectar, setVisibleModalConectar] = useState(false)
    const [visibleModalMenuCad, setVisibleModalMenuCad] = useState(false)
    const [visibleModalCadLink, setVisibleModalCadLink] = useState(false)

    const [visibleModalFeedDelete, setVisibleModalFeedDelete] = useState(false)
    const [visibleModalCompletedFeed, setVisibleModalCompletedFeed] = useState(false)

    //const [medias, setMedias] = useState(true)
    //const [noMedias, setNoMedias] = useState(true)

    const [dataMedias, setDataMedias] = useState([])

    //const statusDeleteSelectProducts = useSelector(state => state.reducerDeleteSelectProducts)

    const medias = useSelector((state) => state.reducerMedias);

    //const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    //const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    //const reducerSincSelectedProduct = useSelector(state => state.reducerSincSelectedProduct)

    useEffect(() => {
        console.log("medias", medias)
        medias?.data?.data?.length > 0 && setDataMedias(medias?.data?.data)
        medias?.data?.data == undefined && setDataMedias([])
    }, [medias])

    useLayoutEffect(() => {
        const isIndeterminate = selectedMedias.length > 0 && selectedMedias.length < dataMedias.length
        setChecked(dataMedias.length == 0 ? false : selectedMedias.length === dataMedias.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedMedias])

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    function toggleAll() {
        setSelectedMedias(checked || indeterminate ? [] : dataMedias)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }


    const sincMulti = () => {
        //console.log("selectedProducts", selectedProducts)
        // const dataId = selectedProducts.map((item) => (item.products.id))
        // dispatch({
        //     type: "SAGA_UPDATE_PRODUCT_MULTI", payload: {
        //         data: dataId,
        //         platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform
        //     }
        // })
        // setTimeout(() => { handleClick() }, 100);
    }

    //const deleteAll = () => {
    // console.log("selectedProducts", selectedProducts)
    // dispatch({
    //     type: "SAGA_DELETE_SELECT_PRODUCTS",
    //     payload: {
    //         itens: selectedProducts.map((item) => (item.id))
    //     }
    // })
    // setTimeout(() => { handleClick() }, 100);

    // }

    //useEffect(() => {
    // if (!statusDeleteSelectProducts.loading && !statusDeleteSelectProducts.error && statusDeleteSelectProducts.data.length == 0) {
    // } else {
    //     const id = "statusDeleteSelectReviews"
    //     if (statusDeleteSelectProducts.loading) {
    //         toast.loading(translation.products.aguarde, { toastId: id })
    //     } else {
    //         if (statusDeleteSelectProducts.error) {
    //             toast.update(id, { render: translation.products.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //         } else {
    //             toast.update(id, { render: translation.products.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //             props.goSearch()
    //         }
    //     }
    // }
    //}, [statusDeleteSelectProducts])

    //useEffect(() => {
    // if (statusUpdateProduct.loading == false && statusUpdateProduct.error == false && statusUpdateProduct.data.length == 0) {
    // } else {
    //     const id = "statusUpdateProduct"
    //     if (statusUpdateProduct.loading) {
    //         toast.loading(translation.products.card.aguarde, { toastId: id })
    //     } else {
    //         if (statusUpdateProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //         else toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //     }
    // }
    //}, [statusUpdateProduct])

    // useEffect(() => {
    // if (statusDeleteProduct.loading == false && statusDeleteProduct.error == false && statusDeleteProduct.data.length == 0) {
    // } else {
    //     const id = "statusDeleteProduct"
    //     if (statusDeleteProduct.loading) {
    //         toast.loading(translation.products.card.aguarde, { toastId: id })
    //     } else {
    //         if (statusDeleteProduct.error) toast.update(id, { render: translation.products.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //         else toast.update(id, { render: translation.products.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //     }
    // }
    //}, [statusDeleteProduct])

    //useEffect(() => {
    // if (reducerSincSelectedProduct.loading == false && reducerSincSelectedProduct.error == false && reducerSincSelectedProduct.data.length == 0) {
    // } else {
    //     const id = "reducerSincSelectedProduct"
    //     if (reducerSincSelectedProduct.loading) {
    //         toast.loading(translation.products.card.aguarde, { toastId: id })
    //     } else {
    //         if (reducerSincSelectedProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //         else {
    //             toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //             props.goSearch()

    //         }
    //     }
    // }
    //}, [reducerSincSelectedProduct])

    useEffect(() => {
        console.log("props table", props)
    }, [props])

    useEffect(() => {
        console.log("dataMedias table", dataMedias)
    }, [dataMedias])

    const changePage = (page) => {
        console.log("changePage: ", page)
        props.setPage(page)
    }

    // useEffect(() => {
    //     if (medias == true && noMedias == true) {
    //         props.setMediasFilter("all")
    //     } else if (medias == true && noMedias == false) {
    //         props.setMediasFilter("only")
    //     } else if (medias == false && noMedias == true) {
    //         props.setMediasFilter("not")
    //     }
    // }, [medias, noMedias])

    const deleteAll = () => {
        dispatch({ type: "SAGA_DELETE_MULTI_MEDIA", payload: { medias: selectedMedias } })
        setTimeout(() => { handleClick() }, 100);
    }

    const [visibeModalAnexoNew, setVisibeModalAnexoNew] = useState(false)
    const [dataModalDeleteCompleted, setDataModalDeleteCompleted] = useState([])
    const [mediaLoadingId, setMediaLoadingId] = useState(null)

    useEffect(() => {
        console.log("dataModalDeleteCompleted", dataModalDeleteCompleted)
    }, [dataModalDeleteCompleted])

    useEffect(() => {
        console.log("mediaLoadingId", mediaLoadingId)
    }, [mediaLoadingId])


    const requestItems = async () => {
        console.log("selectedMedias", selectedMedias)
        for (const item of selectedMedias) {
            console.log("item =>", item)
            try {
                setMediaLoadingId(item.id)
                const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
                const url = `${process.env.REACT_APP_API_URL}/delete_media/${item.id}`;
                const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
                const response = await fetch(url, { method: 'DELETE', headers: headers });
                //const result = await response.json();
                //console.log(`result:`, result);
                setDataModalDeleteCompleted(prevData => [...prevData, item.id]);
                setMediaLoadingId(null)
            } catch (error) {
                console.error(`Erro na requisição do item ${item.media_name}:`, error.message);
            }
        }

        setVisibleModalFeedDelete(false)
        setVisibleModalCompletedFeed(true)
    }

    const closeModal = () => {
        handleClick()

        setTimeout(() => {
            setVisibleModalCompletedFeed(false)
            props.goSearch()
            setDataModalDeleteCompleted([])
        }, 10);
    }

    return (
        medias.loading ?
            // <div style={{ display: "flex", height: "calc(100vh - 140px)", justifyContent: "center", alignItems: "center" }}>
            //     <LoadingLogo />
            // </div> :
            <LazyLoading /> :
            <>
                <ModalMenuMedia
                    visible={visibleModalMenu}
                    setVisibleModal={setvisibleModalMenu}
                    setVisibleModalUploadFiles={setVisibleModalUploadFiles}
                    setVisibleModalConectar={setVisibleModalConectar}
                />
                <ModalUploadFiles
                    getIconFile={props.getIconFile}
                    visible={visibleModalUploadFiles}
                    setVisible={setVisibleModalUploadFiles}
                />

                <ModalConectarAnexo
                    getIconFile={props.getIconFile}
                    item={props.item}
                    visible={visibleModalConectar}
                    setVisible={setVisibleModalConectar}
                />

                <ModalUploadAnexoNew
                    setVisible={setVisibeModalAnexoNew}
                    visible={visibeModalAnexoNew}
                    close={() => { props.goSearch() }}
                //getIconFile={getIconFile}
                //item={props.item}
                />

                <ModalMenuCad
                    visibleModalMenuCad={visibleModalMenuCad}
                    setVisibleModalMenuCad={setVisibleModalMenuCad}
                    setVisibeModalAnexoNew={setVisibeModalAnexoNew}
                    setVisibleModalCadLink={setVisibleModalCadLink}
                />

                <ModalCadLinkNew
                    setVisible={setVisibleModalCadLink}
                    visible={visibleModalCadLink}
                    close={() => { props.goSearch() }}
                />

                <ModalFeedDelete
                    setVisible={setVisibleModalFeedDelete}
                    visible={visibleModalFeedDelete}
                    selectedMedias={selectedMedias}
                    dataModalDeleteCompleted={dataModalDeleteCompleted}
                    setDataModalDeleteCompleted={setDataModalDeleteCompleted}
                    mediaLoadingId={mediaLoadingId}
                    goSearch={props.goSearch}
                    handleClick={handleClick}
                />

                <ModalFeedCompleted
                    setVisible={setVisibleModalCompletedFeed}
                    visible={visibleModalCompletedFeed}
                    title={"Exclusão Completa"}
                    desc={"Excluímos todos os arquivos selecionados"}
                    close={closeModal}
                />


                <div className="px-4 sm:px-6 lg:px-8">
                    {props.navbar && props.navbar}
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Galeria"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Saiba quais arquivos estão disponíveis no seu Dijital e o que você pode fazer com eles."}
                            </p>
                        </div>
                        {!props.productId &&
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <button
                                    onClick={() => {
                                        setVisibleModalMenuCad(true)
                                        //setvisibleModalMenu(true)
                                    }}
                                    type="button"
                                    className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                    {"Cadastrar"}
                                </button>
                            </div>}
                    </div>

                    <BarFilters style={{ paddingRight: "0px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={"Pesquisar por nome"} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>

                            {/* <FiltersMedias */}
                            {/* //medias={medias} setMedias={setMedias}
                            //noMedias={noMedias} setNoMedias={setNoMedias} */}
                            {/* /> */}
                        </span>
                    </BarFilters>



                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedMedias.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => {
                                                    //deleteAll()
                                                    requestItems()
                                                    setVisibleModalFeedDelete(true)
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {"Excluír"}
                                            </button>

                                            {/* <button
                                                onClick={() => {
                                                    //sincMulti()
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {"Baixar"}
                                            </button> */}
                                        </div>


                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {"Data"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {"Nome"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Tipo"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Tamanho"}
                                                    </label>
                                                </th>
                                                {!props.productId &&
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                            {"Produtos"}
                                                        </label>
                                                    </th>}
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">

                                            {props.productId ?
                                                Array(2).fill(
                                                    <ItemTable
                                                        productId={props.productId}
                                                        goSearch={props.goSearch}
                                                        classNames={classNames}
                                                        setSelectedMedias={setSelectedMedias}
                                                        selectedMedias={selectedMedias}
                                                        item={{
                                                            created_at: "2024-01-01",
                                                            quantity_linked_products: "2",
                                                            type: "Tipo",
                                                            size: "1gb",
                                                            media_name: "Teste"
                                                        }} />
                                                ) :
                                                dataMedias?.map((item) => (
                                                    <ItemTable
                                                        productId={props.productId}
                                                        goSearch={props.goSearch}
                                                        classNames={classNames}
                                                        setSelectedMedias={setSelectedMedias}
                                                        selectedMedias={selectedMedias}
                                                        item={item} />
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={changePage} />
            </>
    )
}
