import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 40px;
    overflow: auto;
    max-height: 90vh;
    //gap: 20px;
    background-color: #fff;

    ::-webkit-scrollbar {
        width: 8px; /* largura da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c188; /* cor da barra de rolagem */
        border-radius: 1px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* cor do track */
    }
`
export const Title = styled.div`
    display: flex;
    background-color: #005AFF;
    flex-direction: column;
    padding: 20px;
    color: #fff;

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        label{
            font-size: 14px;
        }
    }

    label{
        font-size: 12px;
    }
`
export const Contents = styled.div`
    display: flex;
    background-color: #fff;
    padding: 20px;
    flex-direction: column;
    gap: 20px;

`

export const ItemLoading = styled.span`
    display: flex;
    //background-color: red;
    
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;

    div{
        animation: ${props => props.active ? "pulse 1s infinite ease-in-out" : "initial"} ;
    }

    @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(2);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`