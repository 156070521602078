import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'


export default function Navbar(props) {

    const location = useLocation()
    const history = useHistory()
    const [route, setRoute] = useState("")

    useEffect(() => {
        console.log("route", location.pathname.split("/").reverse()[0])
        setRoute(location.pathname.split("/").reverse()[0])
    }, [location])


    return (
        <nav style={{ marginBottom: "20px" }} aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <a
                            onClick={() => { history.push("/") }}
                            href="javascript:void(0);"
                            className="text-gray-400 hover:text-gray-500">
                            <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li>


                <li key={"Produtos"}>
                    <div className="flex items-center">
                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                        <a
                            onClick={() => { history.push(`/pedidos${location.search}`) }}
                            href={"javascript:void(0);"}
                            aria-current={"page.current" ? 'page' : undefined}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                            Pedidos
                        </a>
                    </div>
                </li>

                {/* <li key={"Produtos"}>
                    <div className="flex items-center">
                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                        <a
                            //onClick={() => { history.push("/pedidos") }}
                            href={"javascript:void(0);"}
                            aria-current={"page.current" ? 'page' : undefined}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                            #{props?.order}
                        </a>
                    </div>
                </li> */}

                {route == "produtos" &&
                    <li key={"produtos"}>
                        <div className="flex items-center">
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                            <a
                                href={"javascript:void(0);"}
                                aria-current={"page.current" ? 'page' : undefined}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            >
                                Produtos
                            </a>
                        </div>
                    </li>}

            </ol>
        </nav>
    )
}
