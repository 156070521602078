
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, ArrowTopRightOnSquareIcon, EnvelopeIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuItem = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);
    const statusSendEmail = useSelector((state) => state.reducerEmails);

    const sendEmail = () => {
        dispatch({
            type: "SAGA_RESEND_EMAIL",
            payload: { order: props.item },
        });
    }

    useEffect(() => {
        if (statusSendEmail.loading == false && statusSendEmail.error == false && statusSendEmail?.data?.length == 0) {

        } else {
            const id = "statusSendEmail"
            if (statusSendEmail.loading) {
                toast.loading(translations["Aguarde..."][language.data], { toastId: id })
            } else {
                if (statusSendEmail.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    props.setVisibleModal(false)
                    toast.update(id, { render: translations["sales-enviado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                }
            }
        }
    }, [statusSendEmail])

    //const updateStatus = () => {
    // dispatch({
    //     type: "SAGA_RESEND_EMAIL",
    //     payload: { order: props.item },
    // });
    //}

    const reducerUpdateEmailSales = useSelector((state) => state.reducerUpdateEmailSales);

    useEffect(() => {
        if (!(reducerUpdateEmailSales?.loading == false && !reducerUpdateEmailSales?.error == false && reducerUpdateEmailSales?.data?.length == 0)) {
            const id = "reducerUpdateEmailSales"
            if (reducerUpdateEmailSales.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerUpdateEmailSales.error) toast.update(id, { render: "Erro ao atualizar status", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translations["sales-atualizado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //props.setVisibleModal(false)
                    //setEmail("")
                }
            }
        }
    }, [reducerUpdateEmailSales])

    const updateStatus = () => {
        //console.log("saveEmail", props.id, email)
        dispatch({ type: "SAGA_UPDATE_EMAIL_SALES", payload: { id: props.item.id, is_canceled: props.item.is_canceled == 0 ? 1 : 0 }, });
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>

                    <Item
                        image={
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                        }
                        action={() => { props.setVisibleModalEdit(true); props.setVisibleModal(false) }}
                        item={props.item}
                        title={"Editar e-mail"}
                        desc={"Atualizar e-mail do pedido"} />

                    <Item
                        image={<EnvelopeIcon style={{ width: "32px" }} />}
                        action={sendEmail}
                        item={props.item}
                        title={"Reenviar e-mail"}
                        desc={"Enviar ou reenviar o e-mail do pedido"} />

                    <Item
                        image={
                            props.item.is_canceled == 0 ?
                                <LockClosedIcon style={{ width: "32px" }} /> :
                                <LockOpenIcon style={{ width: "32px" }} />
                        }
                        action={updateStatus}
                        item={props.item}
                        title={props.item.is_canceled == 0 ? "Bloquear" : "Desbloquear"}
                        desc={"Bloquear ou desbloquer a página de downloads desse pedido"} />

                    <Item
                        image={<ArrowTopRightOnSquareIcon style={{ width: "32px" }} />}
                        action={() => { window.open(props.item.order_link, "_blank") }}
                        item={props.item}
                        title={"Acessar"}
                        desc={"Acessar página de downloads desse pedido."} />

                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuItem


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}

