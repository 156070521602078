import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  //min-height: 100%;
  //overflow: auto;
  //padding: 30px;
  //padding-left: 280px ;

  //background-color: purple;

  @media screen and (max-width: 360px) {
    padding: 5px 5px 5px 5px !important;
  }

  @media screen and (max-width: 768px) {
    padding-left: 30px;
  }
`;
