import Modal from "react-modal";
import { Action, BarSearch, ColumnItems, ColumnLeft, Container, Contents, FileItem, ListFiles, ListSelected, Title, TitleText } from "./styled";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowTopRightOnSquareIcon, CloudArrowUpIcon, DocumentIcon, FilmIcon, LockClosedIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { border } from "polished";
import IconImage from "../../../../assets/imgs/icons/image.svg";

import IconUrl from "../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
import IconPdf from "../../../../assets/imgs/icons/pdf.svg";

import axios from 'axios';
import { IconFile } from "../card-product/styled";
import { VscSearch } from "react-icons/vsc";
import LoadingLogo from "../../../../components/Loading-logo";
import PaginationModalFiles from "../tailwind/pagination-modal-files";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "600px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "transparent",
        padding: "0px",
        margin: "0px",
        borderRadius: "8px",
        border: "none"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalAnexarGaleria = (props) => {

    //const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const reducerControlVariants = useSelector(state => state.reducerControlVariants)
    const medias = useSelector((state) => state.reducerMedias);
    const language = useSelector((state) => state.reducerLanguage);
    const { id } = useParams();


    const closeModal = () => {
        props.handleClick()
        setTimeout(() => {
            props.setVisible(false)
            dispatch({ type: "VARIANTS_CONTROL_RESET" });
            if (props?.close) {
                props.close()
            } else {
                //dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
                props.goSearch()
            }
        }, 100);

    }

    useEffect(() => {
        //console.log("reducerControlVariants", reducerControlVariants)
    }, [reducerControlVariants])





    useEffect(() => {
        console.log("medias", medias)
    }, [medias])


    const changePage = (page) => {
        console.log("changePage: ", page)
        props.setPageFiles(page)
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Title>
                    <div>
                        <label>Anexar da galeria</label>
                        <XMarkIcon onClick={() => { closeModal() }} style={{ cursor: "pointer" }} width={20} />
                    </div>
                    <label >
                        Pesquise o arquivo e clique em anexar.
                    </label>
                </Title>
                <Contents>

                    <ColumnLeft>

                        {medias?.loading == true ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <LoadingLogo />
                            </div> :
                            <>
                                <BarSearch>
                                    <input
                                        value={props.searchFiles}
                                        onChange={(e) => { props.setSearchFiles(e.target.value) }}
                                        placeholder="Buscar..."
                                    />
                                    <div onClick={() => {
                                        props.goSearchFiles()
                                    }}>
                                        <VscSearch size={20} color={"#ffffff"} />
                                    </div>
                                </BarSearch>

                                <TitleText>Lista de arquivos</TitleText>

                                <ListFiles>
                                    {medias?.data?.data?.map((file) => {
                                        return (
                                            <CardFile setDataFilesTrue={props.setDataFilesTrue} dataFilesTrue={props.dataFilesTrue} file={file} />
                                        )
                                    })}

                                </ListFiles>
                                <PaginationModalFiles
                                    setPage={changePage}
                                />
                            </>
                        }


                    </ColumnLeft>

                    {/* <ColumnItems>
                    <TitleText>Arquivos selecionados</TitleText>
                        <ListSelected>
                            {Array(5).fill(
                                <CardFile />
                            )}
                        </ListSelected>
                    </ColumnItems> */}

                </Contents>
            </Container>

        </Modal >
    )
}

export default ModalAnexarGaleria




const CardFile = (props) => {

    const [loading, setLoading] = useState(false)
    const reducerControlVariants = useSelector(state => state.reducerControlVariants)

    useEffect(() => {
        //console.log("CardFile props", props)
    }, [props])


    const getIconFile = (type, tooltip, media) => {

        //console.log("getIconFile", type, tooltip, media)
        switch (type?.toLowerCase()) {
            case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "webp":
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <img src={IconImage} /> */}
                        
                            <PhotoIcon color="#1f2937" width={20} />
                        

                        {/* {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>} */}
                    </IconFile>
                )
            // case "pdf":
            //     return (
            //         <IconFile
            //         //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
            //         >
            //             <img src={IconPdf} />
            //             {/* {tooltip?.length > 0 &&
            //                 <label>
            //                     {tooltip}
            //                 </label>} */}
            //         </IconFile>
            //     )
            case "mp4": case "avi": case "mkv":
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <img src={IconVideo} /> */}
                        <FilmIcon color="#1f2937" width={20} />
                        {/* {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>} */}
                    </IconFile>
                )
            case "url":
                return (
                    <IconFile
                    // onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsLink45Deg color={iconFile.color} size={iconFile.size} /> */}
                        {/* <img src={IconUrl} /> */}
                        <ArrowTopRightOnSquareIcon color="#1f2937" width={20} />
                        {/* {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>} */}
                    </IconFile>
                )

            default:
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsFillFileEarmarkFill color={iconFile.color} size={iconFile.size} /> */}
                        {/* <img src={IconDefault} /> */}
                        <DocumentIcon color="#1f2937" width={20} />
                        {/* {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>} */}
                    </IconFile>
                )
        }
    }


    const anexarFile = async () => {
        //console.log("reducerControlVariants", reducerControlVariants)
        //console.log("file", props.file)
        setLoading(true)

        const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
        const url = `${process.env.REACT_APP_API_URL}/link_media_product`;
        const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

        await Promise.all(
            reducerControlVariants.data.map((variant) => {
                //console.log("variant", variant);

                return fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({ media_id: props.file.id, product_id: variant.variants.product_id, variant_id: variant.variants.variant_id })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Erro na requisição para variante ${variant.variant_id}: ${response.statusText}`);
                        }
                        //console.log("response", response)
                        return response.json();
                    })
            })
        );

        setLoading(false)
        //props.setDataFilesTrue([...props.dataFilesTrue, props.file])
        props.setDataFilesTrue(prevData => [...prevData, props.file]);
    }

    const verify = () => {
        return props.dataFilesTrue.some(existingMedia => existingMedia.id === props.file.id);
    }

    return (
        <FileItem>
            <div>
                {getIconFile(props.file.type, "props.item.name")}
                <label style={{ fontSize: "14px", display: "flex", maxWidth: "400px", wordBreak: 'break-all' }}>{
                    props.file.media_name}
                </label>
            </div>
            <Action disable={verify()} onClick={() => { !verify() && anexarFile() }} className={verify() ? "bg-gray-200" : "bg-green-600"}>
                {loading ? "Anexando..." : verify() ? "Anexado" : "Anexar"}
            </Action>
        </FileItem>
    )
}