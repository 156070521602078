import Modal from "react-modal";
import { BoxActions, BoxFiles, BoxForm, BoxText, BoxUpload, Container, Contents, DividerLine, FileItem, FileItemLoad, InputLink, InputName, ItemLink, ListFiles, ListLinks, Loading, ProgressBarFill, ProgressContainer, Save, Title } from "./styled";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowTopRightOnSquareIcon, CloudArrowUpIcon, LockClosedIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { border } from "polished";
import IconImage from "../../../../assets/imgs/icons/image.svg";

import IconUrl from "../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
import IconPdf from "../../../../assets/imgs/icons/pdf.svg";

import axios from 'axios';
import { IconFile } from "../card-product/styled";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "540px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "transparent",
        padding: "0px",
        margin: "0px",
        borderRadius: "8px",
        border: "none"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalCadLinkNew = (props) => {

    //const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const reducerControlVariants = useSelector(state => state.reducerControlVariants)
    const language = useSelector((state) => state.reducerLanguage);
    const { id } = useParams();

    const [inputName, setInputName] = useState("")
    const [inputUrl, setInputUrl] = useState("")

    const [links, setLinks] = useState([])
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     if (controlMedias.loading == false && controlMedias.data.length > 0) {
    //         closeModal()
    //     }
    // }, [controlMedias])

    const closeModal = () => {
        props.setVisible(false)
        dispatch({ type: "VARIANTS_CONTROL_RESET" });
        if (props?.close) {
            props.close()
        } else {
            //dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
            props.goSearch()
        }
    }

    useEffect(() => {
        //console.log("reducerControlVariants", reducerControlVariants)
    }, [reducerControlVariants])


    const cadLink = async () => {
        console.log("cadLink")

        //if (JSON.parse(localStorage.getItem("depoimentos@login")).integration.platform == "yampi") {

            //console.log("teste reducerControlVariants", reducerControlVariants)

            // if (props?.item?.variants.product_id) {
            //     dispatch({
            //         type: "SAGA_PRODUCT_URL_MEDIA",
            //         payload: {
            //             product_id: props.item.variants.product_id,
            //             variant_id: "",
            //             urls: urls,
            //         },
            //     });
            // } else {
            //     console.log("teste url sem vinculo")
            //     dispatch({ type: "SAGA_UPLOAD_URL_NEW", payload: urls, });
            // }

        //} else {
            // if (props?.item?.variants.product_id && props?.item?.variants?.variant_id) {
            //     dispatch({
            //         type: "SAGA_PRODUCT_URL_MEDIA",
            //         payload: {
            //             product_id: props.item.variants.product_id,
            //             variant_id: props.item.variants.variant_id,
            //             //variant_id: props.selectedVariante == "" ? props.selectedVariante : props.selectedVariante.variant.variant_id,
            //             urls: urls,
            //         },
            //     });
            // } else {
            //     console.log("teste url sem vinculo")
            //     dispatch({ type: "SAGA_UPLOAD_URL_NEW", payload: urls, });
            // }

            setLoading(true)

            const newUrl = { limit: 0, message: "", media_name: inputName, url: inputUrl, media_extname: "url", media_size: null };
            const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
            const url = `${process.env.REACT_APP_API_URL}/upload_media_and_link`;
            const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
            const response = await fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(newUrl) });
            const result = await response.json();
            console.log("Resposta da API:", result);
            const id_url = result.data.id;

            await Promise.all(
                reducerControlVariants.data.map((variant) => {
                    console.log("variant", variant);
                    return fetch(`${process.env.REACT_APP_API_URL}/link_media_product`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({ media_id: id_url, product_id: variant.variants.product_id, variant_id: variant.variants.variant_id })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`Erro na requisição para variante ${variant.variant_id}: ${response.statusText}`);
                            }
                            console.log("response", response)
                            return response.json();
                        })
                })
            );

            setLinks([...links, { name: inputName, url: inputUrl }])
            setInputUrl("")
            setInputName("")

            setLoading(false)

            // const result = await fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(newUrl) })
            //     .then(response => {
            //         const result = response.json();
            //         console.log("Resposta da API:", result);
            //         //return result;
            //         const id_url = result.data.id
            //         Promise.all(
            //             reducerControlVariants.data.map((variant) => {
            //                 console.log("variant", variant)
            //                 dispatch({
            //                     type: "", payload: {
            //                         media_id: id_url,
            //                         product_id: "198759911",
            //                         variant_id: "811871135"
            //                     }
            //                 })
            //             })
            //         )
            //     })
            //     .catch(error => {
            //         console.log("error", error)
            //     })

            console.log("result", result)

        //}
    }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Title>
                    <div>
                        <label>Cadastrar link</label>
                        <XMarkIcon onClick={() => { closeModal() }} style={{ cursor: "pointer" }} width={20} />
                    </div>
                    <label >
                        Cadastre o link que voce deseja anexar.
                    </label>
                </Title>
                <Contents>

                    <BoxForm>
                        <InputName>
                            <label>Nome</label>
                            <input type="text" value={inputName} onChange={(e) => setInputName(e.target.value)} placeholder="nome" />
                        </InputName>
                        <InputLink>
                            <label>Link</label>
                            <input type="text" value={inputUrl} onChange={(e) => setInputUrl(e.target.value)} placeholder="www." />
                        </InputLink>
                        <Save onClick={() => { inputName != "" && inputUrl != "" ? cadLink() : console.log("vazio") }} className="bg-green-600">
                            {loading ? "Aguarde..." : "Cadastrar"}
                        </Save>
                    </BoxForm>

                    {links.length > 0 &&
                        <ListLinks>
                            <label>
                                Links cadastrados
                            </label>
                            {links.map((item) => {
                                return (
                                    <CardLink item={item} />
                                )
                            })}
                        </ListLinks>}

                </Contents>
            </Container>

        </Modal >
    )
}

export default ModalCadLinkNew


const CardLink = (props) => {

    return (
        <ItemLink>
            <IconFile style={{ cursor: "initial" }}>
                {/* <img src={IconUrl} /> */}
                <ArrowTopRightOnSquareIcon width={24} color="#1f2937" />
            </IconFile>
            <BoxText>
                <p>{props.item.name}</p>
                <label>{props.item.url}</label>
            </BoxText>
        </ItemLink>
    )
}
