import nuvemshopImg from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import dropiImg from "../../../../assets/imgs/dropi-logo.svg";
import shopifyImg from "../../../../assets/imgs/icons-integrations/shopify.png";
import siteImg from "../../../../assets/imgs/site.webp";
import hotmartImg from "../../../../assets/imgs/logo-hotmart.png";
import montinkImg from "../../../../assets/imgs/montink-logo.png";
import rastreioImg from "../../../../assets/imgs/logo-rastreio.svg";
import landPageImg from "../../../../assets/imgs/logo-lp.svg";
import logoYampi from "../../../../assets/imgs/logo-yampi.png";
import logoWoocommerce from "../../../../assets/imgs/logo-woo.png";
import sakImg from "../../../../assets/imgs/icons-integrations/sak.svg";

export const platforms = {
  nuvemshop: {
    image: nuvemshopImg,
    message:
      "Integre sua conta Nuvemshop e entregue produtos digitais de forma automática.",
    title: "Nuvemshop",
    titleColor: "#2C3357",
    action: `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Faça o login em sua conta da nuvemshop que deseja integrar.",
        "2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Dijital novamente.)",
        "3. Pronto sua loja já está integrada ao Dijital.",
      ],
    },
  },
  yampi: {
    image: logoYampi,
    message:
      "Integre sua conta Yampi e entregue produtos digitais de forma automática.",
    title: "Yampi",
    titleColor: "#DC2284",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Faça o login em sua conta da nuvemshop que deseja integrar.",
        "2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Dijital novamente.)",
        "3. Pronto sua loja já está integrada ao Dijital.",
      ],
    },
  },
  woocommerce: {
    image: logoWoocommerce,
    message:
      "Integre sua conta Woocommerce e comece a adicionar produtos digitais na sua loja",
    title: "Woocommerce",
    titleColor: "#8053B4",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Faça o login em sua conta da nuvemshop que deseja integrar.",
        "2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Dijital novamente.)",
        "3. Pronto sua loja já está integrada ao Dijital.",
      ],
    },
  },
  shopify: {
    image: shopifyImg,
    message:
      "Integre sua conta Shopify e entregue produtos digitais de forma automática.",
    title: "Shopify",
    titleColor: "#5B8A3C",
    action: `https://#loja_shopify#.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT_ID}&scope=write_products,read_products,read_orders,read_product_listings,read_collection_listings,write_customers,write_orders,write_draft_orders,write_fulfillments,write_script_tags,write_shipping,read_inventory,read_script_tags&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/shopify&state=gagasgaswqeq&grant_options[]=`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Faça o login em sua conta da shopify que deseja integrar.",
        "2. Digite o endereço da loja que deseja integrar:",
        "3. Clique no botão Conectar. (você será redirecionado à shopify para autorizar a integração e assim que terminar será redirecionado para o Lily Reviews novamente.)",
        "4. Pronto sua loja já está integrada ao Lily Reviews.",
      ],
    },
  },
  dropi: {
    image: dropiImg,
    message: "Utilize as avaliações do Dropi.",
    title: "Dropi",
    titleColor: "#182390",
    //action: `https://magos.dropi.com.br/api/authorize/1000?callback_url=${process.env.REACT_APP_BASE_URL}/integracoes/dropi`,
    action: `https://app.dropi.com.br/api/authorize/1000?callback_url=${process.env.REACT_APP_BASE_URL}/integracoes/dropi`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Clique no botão Conectar. (você será redirecionado ao Dropi para autorizar a integração e assim que terminar será redirecionado para o Lily Reviews novamente)",
        "2. Pronto sua conta no Dropi já está integrada ao Lily Reviews.",
      ],
    },
  },

  custom: {
    image: "https://celulaweb.com.br/wp-content/uploads/2021/02/icon-site.png",
    message:
      "Agora você pode criar avaliações e perguntas no seu site, loja virtual, sistema web e etc.",
    title: "Custom",
    titleColor: "#FE852B",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Digite a url do site que deseja integrar:",
        "2. Clique no botão Conectar",
        "3. Pronto seu site já está integrado ao Lily Reviews.",
      ],
    },
  },
  hotmart: {
    image: hotmartImg,
    message:
      "Integre sua conta Hotmart e comece a criar avaliações e perguntas na sua loja.",
    title: "Hotmart",
    titleColor: "#FF4D1A",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Digite a url do site que deseja integrar:",
        "2. Faça o login na conta da hotmart que deseja integrar.",
        "3. Dentro da Hotmart, entre no menu ferramentas e procure a opção Credenciais Hotmart",
        "4. Clique em criar credenciais -> API Hotmart e coloque um nome para sua credencial",
        "5. Acesse sua credencial criada e preencha os dados abaixo:",
        "6. Copie o link abaixo.",
        "7. Dentro da Hotmart, entre no menu ferramentas e procure a opção Webhook",
        "8. Clique em criar configuração, cole o link no campo Url para envio de dados.",
        "9. Selecione o evento compra completa, preencha o restante dos dados, salve e volte ao Lily Reviews",
        "10. Clique no botão Conectar",
        "11. Pronto sua conta Hotmart já está integrada ao Lily Reviews.",
      ],
    },
  },
  montink: {
    image: montinkImg,
    message: "Agora você pode criar avaliações e perguntas na sua loja Montink",
    title: "Montink",
    titleColor: "#070E2B",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Digite o token da loja Montink que deseja integrar:",
        "2. Clique no botão Conectar",
        "3. Pronto seu loja Montink já está integrado ao Lily Reviews.",
      ],
    },
  },

  rastreio: {
    image: rastreioImg,
    message: "Combine o poder do Rastreio com o Lily",
    title: "Rastreio",
    titleColor: "#0083CA",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Clique em conectar, para ser direcionado ao app Rastreio",
        "2. Dentro do app Rastreio, preencha os dados e integre.",
        "3. Retorne ao app Dijital e pronto. você está integrado ",
      ]
    }
  },

  landpage: {
    image: landPageImg,
    message: "Agora você pode criar avaliações e perguntas na sua Land Page.",
    title: "Land Page",
    titleColor: "#05BFDA",
    action: `#`,
    body: {
      title: "Passo a passo",
      steps: [
        "1. Clique em conectar, para ser direcionado ao app Rastreio",
        "2. Dentro do app Rastreio, preencha os dados e integre.",
        "3. Retorne ao app Dijital e pronto. você está integrado ",
      ],
    },
  },

  sak: {
    image: sakImg,
    message: "Combine o poder do SAK para entregar produtos digitais pelo whatsapp",
    title: "Sak",
    titleColor: "#00E673",
    action: `#`,
    body: {
      title: "translation.integrations.dataCard.sak.title",
      steps: [
        "translation.integrations.dataCard.sak.steps1",
        "translation.integrations.dataCard.sak.steps2",
        "translation.integrations.dataCard.sak.steps3",
        "translation.integrations.dataCard.sak.steps4",
      ]
    }
  },

};
