import { EllipsisVerticalIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { TextQtd } from "../../../../medias/components/tailwind/item-table/styled"
import { useHistory } from "react-router-dom"

const ItemTableProduct = (props) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const dispatch = useDispatch()
    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    const history = useHistory()

    console.log("name ::", props)

    return (
        <>
            <tr key={"person.email"} className={"even:bg-gray-50"}>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img
                            //onClick={()=>{window.open(props.item.product.url, "_blank")}}
                            style={{ width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} src={props.item.product.image_url} />
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {props.item.product.name}
                </td>

                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                    #{props.order}
                </td>

                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        {/* <EllipsisVerticalIcon onClick={() => { setvisibleModalMenu(true) }} style={{ height: "32px", cursor: "pointer" }} /> */}

                    </span>
                </td>
            </tr >
        </>

    )
}

export default ItemTableProduct