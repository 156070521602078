import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Alert from "../../../../components/Alert/index";
import Contents from "../../../../components/Contents";
import CardPlano from "../../components/CardPlano/index";
import ModalCupom from "../../components/ModalCupom/index";
import FormaPagamento from "../../components/FormaPagamento/index";
import TablePagamentos from "../../components/TablePagamentos/index";
import DadosNotaFiscal from "../../components/DadosNotaFiscal/index";
import Avisos from "../../components/Avisos";
import { Container, Cupom } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PagamentoProvider } from "../../hooks/usePagamento";
import { AiOutlineLoading } from "react-icons/ai";
import {
  displayScript,
  isVisibleScript,
} from "../../../../utils/displayScript";
import Loading from "../../../../components/Loading";
import LoadingLogo from "../../../../components/Loading-logo";
import CardPlanoNew from "../../components/CardPlanoNew";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ContainerNew } from "../../../dashboard/pages/home-new/styled";
import LazyLoadingPlan from "../../../../components/lazy-loading-plan";
import SelectCoin from "../../components/select-coin";
import { useState } from "react";
import CardPlanoSelect from "../../components/CardPlanoSelect";

const HomeNew = () => {
  const payments = useSelector((state) => state.reducerPayments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SAGA_LIST_PAYMENTS", payload: selected.id });
  }, [dispatch]);

  let pagamentoSimplificado = payments?.data?.pagamentoSimplificado;


  const getCards = () => {

    // for (const plan in payments?.data?.dadosPlanos?.planos) {
    //  
    //   return (
    //     <CardPlanoNew item={plan} current={payments?.data?.currentPlan} />
    //   )
    // }
  }
  const coins = [
    { id: "BRL", name: 'Real (R$)' },
    { id: "MXN", name: 'Peso Mexicano (MX$)' },
    { id: "ARS", name: 'Peso Argentino (AR$)' },
    { id: "COP", name: 'Peso Colmbiano (CO$)' },
    { id: "CLP", name: 'Peso Chileno (CL$)' },
    { id: "USD", name: 'Dólar (US$)' },
    { id: "EUR", name: 'Euro (€)' },
    { id: "GBP", name: 'Libra (£)' },
  ]

  const changeValue = (value) => {
    console.log("changeValue", value)
    setSelected(value)
    dispatch({ type: 'SAGA_LIST_PAYMENTS', payload: value.id })
  }


  const [selected, setSelected] = useState(coins[0])

  return (
    <LayoutNew>
      <ContainerNew>

        {
          payments.loading == false && Object.values(payments.data).length > 1 ?

            <Contents>
              {payments.loading ? (
                // <Loading />
                <LazyLoadingPlan />
              ) : (
                <PagamentoProvider>
                  <Container>
                    <Avisos avisoPlus={pagamentoSimplificado} />

                    <SelectCoin selected={selected} setSelected={setSelected} changeValue={changeValue} coins={coins} />

                    {/* <div style={{ display: "flex", gap: "30px" }}>

                      {Object.entries(payments?.data?.dadosPlanos?.planos).map((plan) => {
                        return (<CardPlanoNew idPlan={plan.id} features={payments?.data?.dadosPlanos?.funcionalidades} item={plan} current={payments?.data?.currentPlan} />)
                      })}
                    </div> */}

                    <div style={{ display: "flex", gap: "20px" }}>
                      {payments?.data?.plansPlus?.map((item, index) => {
                        if (item.legado == 0 || item.id_plano_externo == payments?.data?.currentPlan.id) {
                          return (
                            //"item"
                            <div style={{ display: "flex", gap: "20px", marginTop: "30px", }}>
                              <CardPlanoSelect key={index} plan={item} currentPlan={payments?.data?.currentPlan} />
                            </div>
                          )
                        }
                      })}
                    </div>

                    {!pagamentoSimplificado && (
                      <>
                        <Cupom>
                          <ModalCupom
                            cupomInitialValue={payments?.data?.user?.coupon ?? ""}
                          />
                        </Cupom>
                        <FormaPagamento />
                      </>
                    )}
                    <TablePagamentos
                      faturasInitialValue={payments?.data?.transactions ?? []}
                    />
                    {!pagamentoSimplificado && (
                      <DadosNotaFiscal
                        dadosFiscais={payments?.data?.taxUser ?? {}}
                        listaUfs={payments?.data?.ufList ?? []}
                      />
                    )}
                  </Container>
                </PagamentoProvider>
              )}
            </Contents> :
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
              {/* <LoadingLogo /> */}
              <LazyLoadingPlan />
            </div>
        }

        {isVisibleScript(false)}
      </ContainerNew>
    </LayoutNew>
  );
};

export default HomeNew;
