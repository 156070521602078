import { put, call, delay, all } from "redux-saga/effects";
import { POST, GET, PUT, POST_CUSTOM, POST_FORMDATA } from "../../../../services/api";
import { toast } from "react-toastify";
import { translations } from "../../../../utils/language";

export function* listProducts(action) {

  console.log("listProducts action", action)

  try {
    yield put({ type: "PRODUCTS_REQUEST" });
    //yield delay(2000);
    const result = yield call(GET, {
      //endpoint: `/products/pagination?per_page=${action.payload.perPage}&search=${action.payload.search}`,
      endpoint: `/products/pagination?search=${action.payload.search}&per_page=${action.payload.perPage}&page=${action.payload.page}&media=${action.payload.media}`,
    });
    // alert(JSON.stringify(result.data.products));

    yield put({ type: "PRODUCTS_SUCCESS", payload: result.data });
  } catch (err) {


  }
}

export function* updateProduct(action) {
  yield put({ type: "PRODUCT_UPDATE_REQUEST" });
  //yield delay(200);
  const result = yield call(PUT, {
    endpoint: `/products/${action.payload.id}`,
    data: action.payload,
  });
  if (result === false) {
    yield put({ type: "PRODUCT_UPDATE_ERROR" });
  } else {
    yield put({ type: "PRODUCT_UPDATE_SUCCESS", payload: result.data });
  }
}

export function* linkMediaProducts(action) {

  yield put({ type: "MEDIAS_UPLOAD_REQUEST", })
  const result = yield call(POST, { endpoint: `/link_media_product`, data: action.payload })
  if (result === false) {
    yield put({ type: "MEDIAS_UPLOAD_ERROR" })
  } else {
    yield put({ type: "MEDIAS_UPLOAD_SUCCESS", payload: result.data })
  }

  // yield call(POST, {
  //   endpoint: `/link_media_product`,
  //   data: action.payload,
  // });
}

export function* deleteLinkMediaProducts(action) {

  //console.log("teste listVariants", action)
  yield put({ type: "VARIANTS_LIST_REQUEST" })
  const result = yield call(POST, { endpoint: `/delete_link_media_product`, data: action.payload, });
  if (result == false) {
    yield put({ type: "VARIANTS_LIST_ERROR", payload: result.data })
  } else {
    yield put({ type: "VARIANTS_LIST_SUCCESS", payload: result.data })
  }

//  const id = "deleteLinkMediaProducts"
//   try {
//     yield call(POST, { endpoint: `/delete_link_media_product`, data: action.payload, });
//     toast.success(translations["medias-excluido-sucesso"][localStorage.getItem("language")]);
//     //yield put({ type: "SAGA_LIST_PRODUCTS", payload: { search: "", perPage: 20, page: 1, } })
//     yield put({ type: "PRODUCT_CONTROL_REMOVE_MEDIA", payload: null })
//     yield put({ type: "PRODUCT_CONTROL_REMOVE_MEDIA", payload: false })
//     console.log("PRODUCT_CONTROL_REMOVE_MEDIA")

//   } catch {
//     toast.error("Erro ao remover mídia!");
//   }
}

export function* listVariants(action) {
  console.log("teste listVariants", action)
  yield put({ type: "VARIANTS_REQUEST" })
  const result = yield call(GET, { endpoint: `/variants/${action.payload.id}?page=${action.payload.page}&perPage=40`, });

  if (result == false) {
    yield put({ type: "VARIANTS_ERROR", payload: result.data })
  } else {
    yield put({ type: "VARIANTS_SUCCESS", payload: result })
  }
  //yield put({ type: "VARIANTS_SUCCESS", payload: result.data.variants });
}


export function* addMedias(action) {

  yield put({ type: "PRODUCT_CONTROL_MEDIA_REQUEST" })

  for (const file of action.payload.files) {
    yield put({ type: "PRODUCT_ADD_MEDIA_REQUEST", payload: file })
    //yield delay(3000)
    const resultItem = yield call(POST_FORMDATA, {
      product_id: action.payload.product_id,
      variant_id: action.payload.variant_id,
      media: file,
      limit: 0,
      message: "",
      media_name: file.name
    })
    if (resultItem.description == "Você não possui memória disponível") {
      toast.error(resultItem.description + ". Faça um upgrade do seu plano.")
    }

    if (resultItem == false) {
      yield put({ type: "PRODUCT_ADD_MEDIA_ERROR", payload: file })
    } else {
      yield put({ type: "PRODUCT_ADD_MEDIA_SUCCESS", payload: file })
      yield put({ type: "PRODUCT_CONTROL_MEDIA_ADD", payload: file })
    }

  }

  yield put({ type: "PRODUCT_CONTROL_MEDIA_SUCCESS" })
  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_CHANGE", payload: null })
  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_CHANGE", payload: false })
}



export function* LinkMedias(action) {

  yield put({ type: "PRODUCT_CONTROL_MEDIA_REQUEST" })

  for (const media of action.payload.medias) {
    yield put({ type: "PRODUCT_LINK_MEDIA_REQUEST", payload: media })
    //yield delay(3000)

    const resultItem = yield call(POST, {
      endpoint: `/link_media_product`,
      data: {
        product_id: action.payload.product_id,
        variant_id: action.payload.variant_id,
        media_id: media.id,
      }
    });

    if (resultItem == false) {
      yield put({ type: "PRODUCT_LINK_MEDIA_ERROR", payload: media })
    } else {
      yield put({ type: "PRODUCT_ADD_MEDIA_SUCCESS", payload: media })
      yield put({ type: "PRODUCT_LINK_MEDIA_SUCCESS", payload: media })
    }

  }

  yield put({ type: "PRODUCT_CONTROL_MEDIA_SUCCESS" })

  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_LINK_CHANGE", payload: null })
  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_LINK_CHANGE", payload: false })
}

export function* urlMedias(action) {


  console.log("urlMedias action", action)
  yield put({ type: "PRODUCT_CONTROL_MEDIA_REQUEST" })

  for (const url of action.payload.urls) {
    yield put({ type: "PRODUCT_URL_MEDIA_REQUEST", payload: url })
    //yield delay(3000)
    const resultItem = yield call(POST_FORMDATA, {
      product_id: action.payload.product_id,
      variant_id: action.payload.variant_id,
      url: url.url,
      limit: 0,
      message: "",
      media_name: url.urlName
    })
    if (resultItem == false) {
      yield put({ type: "PRODUCT_URL_MEDIA_ERROR", payload: url })
    } else {
      yield put({ type: "PRODUCT_URL_MEDIA_SUCCESS", payload: url })
      yield put({ type: "PRODUCT_CONTROL_MEDIA_ADD", payload: url })
    }

  }

  yield put({ type: "PRODUCT_CONTROL_MEDIA_SUCCESS" })
  //yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_CHANGE", payload: { status: false } })
  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_URL_CHANGE", payload: null })
  yield put({ type: "PRODUCT_CONTROL_CLOSE_MODAL_URL_CHANGE", payload: false })
}


export function* updateDataProduct(action) {

  yield put({ type: "PRODUCT_DATA_UPDATE_REQUEST", })
  //yield delay(200);
  //const result = yield call(PUT, { endpoint: `/products/update/${action.payload.id}` })
  toast.loading("Aguarde...", { toastId: "updateDataProduct" })
  const result = yield call(PUT, { endpoint: `/products/update`, data: action.payload, });
  if (result == false) {
    yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
    toast.update("updateDataProduct", { render: "Erro ao tentar sincronizar produto.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  } else {
    yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: result.data })
    toast.update("updateDataProduct", { render: "Produto sincronizado com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })

  }
}

export function* updateDataProductMulti(action) {

  yield put({ type: "PRODUCT_DATA_UPDATE_REQUEST", })
  toast.loading("Aguarde...", { toastId: "updateDataProduct" })
  //const result = yield call(PUT, { endpoint: `/products/update/multi`, data: action.payload, });
  const result = null
  try {
    for (let index = 0; index < action?.payload?.data?.length; index++) {
      const itemResult = yield call(PUT, {
        endpoint: `/products/update`, data: {
          integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
          product: action.payload.data[index].products,
        }
      });
      console.log("itemResult", itemResult)
    }
    yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: "result.data" })
    toast.update("updateDataProduct", { render: "Produtos sincronizados com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
  } catch (error) {
    console.log("error", error)
    yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
    toast.update("updateDataProduct", { render: "Erro ao tentar sincronizar produtos.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  }

}

export function* searchPlatformAllProducts(action) {

  yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_REQUEST" });
  const result = yield call(GET, { endpoint: `products/pagination/${action.payload}` });
  console.log("result", result)
  if (result === false) {
    yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_ERROR" });
  } else {
    yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_SUCCESS", payload: result.data });
  }

  if (result.data.data.maxItems == 0) {
    return null
  }

  if (action.payload == "shopify") {
    yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });
    let nextPage = 1
    let cont = 1;
    do {
      yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: cont });
      //const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: nextPage, perPage: result.data.data.perPage } });
      const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: nextPage, perPage: 10 } });
      console.log("resultItem", resultItem)
      nextPage = resultItem?.data?.data?.nextPage
      console.log("nextPage", nextPage)
      if (resultItem == false) {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
        toast.error("Erro ao tentar atualização.")
        break
      } else {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: nextPage })
      }
      cont++
    } while (nextPage != null);

  } else {
    yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });
    for (let index = 0; index < result.data.data.maxPages; index++) {
      yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: index + 1 });
      //const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: index + 1, perPage: result.data.data.perPage } });
      const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: index + 1, perPage: 10 } });

      if (resultItem == false) {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
        toast.error("Erro ao tentar atualização.")
        break
      } else {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: result.data.data.maxItems })
      }
    }
  }

  // if (action.payload == "nuvemshop") {
  //   yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_REQUEST" });

  //   const result = yield call(GET, { endpoint: `products/pagination/${action.payload}` });
  //   if (result === false) {
  //     yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_ERROR" });
  //   } else {
  //     yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_SUCCESS", payload: result.data });

  //     yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });

  //     for (let index = 0; index < result.data.data.maxPages; index++) {
  //       yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: index + 1 });
  //       const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: index + 1, perPage: result.data.data.perPage } });
  //       if (resultItem == false) {
  //         yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
  //         toast.error("Erro ao tentar atualização.")
  //         break
  //       } else {
  //         console.log("result item", resultItem)
  //         if (index + 1 == result.data.data.maxPages) yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: result.data.data.maxItems })
  //       }
  //     }
  //   }

  // } else if (action.payload == "shopify") {

  //   yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_REQUEST" });

  //   const result = yield call(GET, { endpoint: `products/pagination/${action.payload}` });
  //   if (result === false) {
  //     yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_ERROR" });
  //   } else {
  //     yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_SUCCESS", payload: result.data });

  //     yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });

  //     console.log("saga teste result", result)

  //     let nextPage = 0
  //     for (let index = 0; nextPage != null; index++) {
  //       console.log("nextPage", nextPage)
  //       yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: index + 1 });
  //       const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: nextPage == 0 ? 1 : nextPage, perPage: result.data.data.perPage } });
  //       console.log("resultItem", resultItem)
  //       nextPage = result.data.data.nextPage

  //       if (resultItem == false) {
  //         yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
  //         toast.error("Erro ao tentar atualização.")
  //         break
  //       } else {
  //         console.log("result item", resultItem)
  //         if (nextPage == null) {
  //           yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: nextPage })
  //           break
  //         }
  //       }

  //     }
  //   }
  // }

  // console.log("saga searchPlatformAllProducts action", action)

  // yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_REQUEST" });

  // const result = yield call(GET, { endpoint: `products/pagination/${action.payload}` });
  // if (result === false) {
  //   yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_ERROR" });
  // } else {
  //   yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_SUCCESS", payload: result.data });

  //   yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });

  //   console.log("saga teste result", result)

  //   let nextPage = null
  //   for (let index = 0; index < result.data.data.maxPages; index++) {
  //     yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: index + 1 });
  //     const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: action.payload == "shopify" && nextPage != null ? nextPage : index + 1, perPage: result.data.data.perPage } });
  //     nextPage = result.data.nextPage

  //     if (resultItem == false) {
  //       yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
  //       toast.error("Erro ao tentar atualização.")
  //       break
  //     } else {
  //       console.log("result item", resultItem)
  //       if (index + 1 == result.data.data.maxPages) yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: result.data.data.maxItems })
  //     }

  //   }

  //}

}