import styled, { keyframes } from "styled-components";

const progress = keyframes`
  0% { 
    background-position: -100%; 
  }
  100% { 
    background-position: 200%; 
  }
`;

export const Container = styled.div`
    display: flex;
    gap: 20px;
    //background-color: #f2f2f2;
    height: calc(100vh - 140px);
    overflow: hidden;
    flex-direction: column;
`

export const Contents = styled.div`
    display: flex;
    width: 50px;
    background-color: #f2f2f2;
    border-radius: 8px;
    position: relative;
    min-height: 2000px;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%);
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

