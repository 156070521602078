import { Card, Price } from './styled'
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Button from '../../../../components/Button/index'
//import { getTranslation } from '../../../../translations';
import { Buffer } from 'buffer';

const CardPlanoSelect = (props) => {

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    console.log("props card plan", props)

    const linkPLus = () => {
        console.log("test")
        window?.empreenderPlusApiMethods?.verifyIfLoggedinBeforeRedirect(process.env.REACT_APP_EPLUS_DOMAIN + `/planos?app=LILY&p=${Buffer.from(`${props.plan.id}`).toString('base64')}&chave=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`)

    }

    return (
        <Card>
            <h3>{props.plan.nome}</h3>
            <Price>
                <span>{props.plan.valorConvertido == null ? props.plan.valor : props.plan.valorConvertido}</span>
                <small>{props.plan.valorConvertido != null && ` (${props.plan.valor})`} / {"mês"}</small>
            </Price>
            <ul>
                {
                    props?.plan?.funcionalidades?.map((func, index) => {
                        return (
                            <li key={index}>{func.ativa ? <AiOutlineCheck /> : <AiOutlineClose />} {func.nome}</li>
                        )
                    })
                }
                {/* {
                    props.plan.funcionalidades.map((funcionalidade, index) => {
                        if (!funcionalidade['ativo']) return '';

                        let textoFormatado = funcionalidade.nome;
                        let temAcesso = false;

                        if (idPlan in funcionalidade.planos) {
                            temAcesso = true;

                            let dadosSubstituir = funcionalidade.planos[idPlan];

                            Object.entries(dadosSubstituir).forEach(values => {
                                let regex = new RegExp(values[0].replace('[', '\\[').replace(']', '\\]'), 'g');
                                textoFormatado = textoFormatado.replace(regex, values[1]);
                            });

                        } else {
                            let regex = new RegExp(`\\[[^\\]]+\\]`, 'g');
                            textoFormatado = textoFormatado.replace(regex, '-');
                        }

                        return <li key={index}>{temAcesso ? <AiOutlineCheck /> : <AiOutlineClose />} {textoFormatado}</li>
                    })
                } */}

            </ul>

            


            {process.env.REACT_APP_HABILITAR_PLUS 
                //&& currentPlan['id'] != idPlan
                &&
                <Button onClick={() => { linkPLus() }} type="button" width="100%" marginTop="20px" background="#ffc400" color="#5e6278">
                    <a rel="noreferrer" onClick={() => { linkPLus() }} style={{ color: '#651006' }}>{props.currentPlan.id == props.plan.id_plano_externo ? "Plano Atual": "Ver Plano"}</a>
                </Button>
                // :
                // <Button type="button" width="100%" marginTop="20px" background="#FEE79E" color="#5e6278" style={{ pointerEvents: 'none' }}>
                //     {currentPlan['id'] != idPlan ? translation.assinatura.cardPlano.trocarPlano : translation.assinatura.cardPlano.planoAtual}
                // </Button>
            }
        </Card>
    );
}

export default CardPlanoSelect