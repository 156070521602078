import React, { useEffect, useState } from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { useParams } from 'react-router-dom';
import TableVariants from '../../components/tailwind/table-variants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// import TableReviews from '../../components/tailwind/table-review';

const Variants = () => {

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { id } = useParams();

    const variants = useSelector((state) => state.reducerVariants);

    const variants_teste = useSelector((state) => state.reducerVariants);

    useEffect(() => {
        console.log("variants_teste data data", variants_teste.data.data)
    }, [variants_teste])


    useEffect(() => {
        //console.log("midiaID", id)
        //dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
    }, [])

    useEffect(() => {
        console.log("variants", variants)
    }, [variants])

    useEffect(() => { goSearch() }, [page,])


    const goSearch = () => {
        //dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
        dispatch({ type: "SAGA_LIST_VARIANTS", payload: { id: id, page: page, }, });
    }



    const reducerVariantsList = useSelector(state => state.reducerVariantsList)
    useEffect(() => {
        if (reducerVariantsList.loading == false && reducerVariantsList.error == false && reducerVariantsList.data.length == 0) {
        } else {
            const id = "reducerVariantsList"
            if (reducerVariantsList.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerVariantsList.error) toast.update(id, { render: "Erro na operação", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Removido com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
                    //window.location.reload()
                    goSearch()
                }
            }
        }
    }, [reducerVariantsList])

    return (
        <LayoutNew>
            {/* <TableReviews productId={id} navbar={<Navbar />} /> */}
            <TableVariants
                variants={variants}
                goSearch={goSearch}
                setPage={setPage}
                page={page}
            />
        </LayoutNew>
    )
}

export default Variants